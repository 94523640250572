.single__assets {
    margin-bottom: 20px;
}
.single__asset__form {
    margin-bottom: 5px;
}
.single__asset__form .label {
    text-align: left;
}
.save__button {
    margin-top: 20px;
}
.save__button .btn {
    margin-right: 13px;
}
.tag__name .label {
    color: #D8000C;
}
.error, .errmsg{
    color: #D8000C;
}

.info-msg,
.success-msg,
.warning-msg,
.error-msg {
  margin: 10px 0;
  padding: 10px;
  border-radius: 3px 3px 3px 3px;
}
.info-msg {
    color: #D8000C;
    background-color: #ecfaff;
    border: 0.5px solid #d5d5d5;
}
.success-msg {
  color: #270;
  background-color: #DFF2BF;
}
.warning-msg {
  color: #9F6000;
  background-color: #FEEFB3;
}
.error-msg {
  color: #D8000C;
  background-color: #FFBABA;
}
.month-div {
    background-color: whitesmoke;
    height: 100px;
    border: 1px solid #e9e9e9;
    margin: 10px;
    text-align: center;
    vertical-align: middle;
    padding-top: 25px;
    border-radius: 8px;
    box-shadow: 0 0 2px 0 rgba(145 158 171 / 0.2), 5px 7px 16px 3px rgba(145 158 171 / 0.12);
}

.lock-div { border: 1px solid #e9e9e9;
  background-color: whitesmoke;
  margin: 10px;
  text-align: center;
  vertical-align: middle;
  border-radius: 8px;
}
.lock-div button {
  margin: 5px;
}
.col-md-1-5 {
  width: 10.3333%;
}