.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root, .css-1bn53lx{
    min-width: 178PX;
    width: 100%;
    height: 32px;
    border-color: #ccc !important;
}

/* Ensure the input field matches the dropdown exactly in height, width, and padding */
.custom-date-picker-input .MuiInputBase-root {
    height: 36px;                /* Matches dropdown height */
    padding: 0 12px;             /* Matches dropdown padding */
    border: 1px solid #ccc;      /* Neutral border */
    border-color: '#2196f3';
    border-radius: 4px;          /* Matches dropdown border-radius */
    font-size: 14px;
    box-sizing: border-box;
    color: #495057;
    width: 280px;
}

/* Hover state with light gray border */
.custom-date-picker-input .MuiInputBase-root:hover {
    border-color: #ccc;
}

/* Removed focus border color */
.custom-date-picker-input .Mui-focused {
    border-color: #ccc !important;
    box-shadow: none !important;
}

/* Error state with red border */
.custom-date-picker-input.Mui-error .MuiInputBase-root {
    /*border-color: #dc3545; */      /* Red border for error */
}

/* Ensure padding inside the input field */
.custom-date-picker-input input {
    padding: 8px 0;
    height: 100%;
    box-sizing: border-box;
}

/* Helper text for error message alignment */
.custom-date-picker-input .MuiFormHelperText-root {
    margin-top: 4px;
    font-size: 12px;
    /*color: #dc3545;              /* Error message in red */
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: #ced4da !important; 
}
.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: #ced4da !important; 
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input, .css-1uvydh2{
    font-family: "Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    color: #383838 !important;
    font-weight: 400;
}
.css-z3c6am-MuiFormControl-root-MuiTextField-root{
    width: 100%;
    font-size: 0.9rem;
    line-height: 1;
}
